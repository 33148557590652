.Span {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 50%;
  left: 0;
  right: 0;
  display: block;
  margin: auto;
  top: 0;
  bottom: 0;
  position: absolute;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: 5px solid #003A65;
  border-bottom-color: transparent;
  animation: spinner 1s linear infinite paused;
  -webkit-animation: spinner 1s linear infinite;

}

@keyframes spinner {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}
