
.slick-arrow {
    position: absolute !important;
    width: 10% !important;
    height: 100% !important;
    z-index: 9 !important;
    transition: all ease 0.3s !important;
    -webkit-transition: all ease 0.3s !important;
    -moz-transition: all ease 0.3s !important;
    -ms-transition: all ease 0.3s !important;
    -o-transition: all ease 0.3s !important;
    &:before {
        display: none;
    }
}
.slick-next {
    right: 0 !important;
    &:hover {
        transition: all ease 0.3s !important;
        -webkit-transition: all ease 0.3s !important;
        -moz-transition: all ease 0.3s !important;
        -ms-transition: all ease 0.3s !important;
        -o-transition: all ease 0.3s !important;
        background: linear-gradient(
            90deg,
            rgba(78, 151, 216, 0) 0%,
            rgba(78, 151, 216, 0.3309698879551821) 52%,
            rgba(78, 151, 216, 0.5886729691876751) 100%
        ) !important;
    }
}
.slick-prev {
    left: 0 !important;
    &:hover {
        background: linear-gradient(
            90deg,
            rgba(78, 151, 216, 0.5886729691876751) 0%,
            rgba(78, 151, 216, 0.3309698879551821) 52%,
            rgba(78, 151, 216, 0) 100%
        ) !important;
    }
}

.ant-pagination-options-size-changer{
    display: none !important; 
}