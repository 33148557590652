.wrapper {

}

.Slider {
  &:first-child {
    margin-bottom: 30px;
  }

  width: 100%;

  & > div {
    height: 300px;
    margin-bottom: 30px;

    img {
      object-fit: cover;
      width: 100%;
      height: 300px;
    }
  }

  & > div + div {
    height: 200px;
    margin-bottom: 0;

    img {
      height: 200px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
}

.sublist {
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin-top: 25px;
  line-height: 54px;
  color: #003A65;
  margin-bottom: 60px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 30px;
  }
}
