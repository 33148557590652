.content {
  position: absolute;
  width: max-content;
  top: 40px;

  & > div {
    z-index: -5;
    height: 50px;
    opacity: 0;
    width: 116px;
    position: absolute;
    top: -50px;
  }

  a {
    text-transform: uppercase;
    background: white;
    color: #003a65;
    text-align: left;
    font-size: 14px;
    z-index: 989;
    font-weight: bold;
    padding: 9px 18px;
    display: block;
    transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    -ms-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;

    &:hover {
      background-color: #003a65;
      color: white

    }
  }
}

.navItem {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-family: Montserrat;
  font-style: normal;
  color: #003a65;
  align-items: center;
  padding: 8px 7px;
  margin: 0 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  position: relative;

  &.active {
    text-decoration: underline;
  }

  @media screen and (max-width: 1200px) {
    font-size: 12px;

  }

  .content {
    height: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow: hidden;
    z-index: 11;
    background: white;
  }

  svg {
    margin-left: 2px;
  }
}

.dropdownButton:hover {
  .content {
    height: auto;
  }
}
