///////////////    About PREZIDENTNOTES
.wrapper{
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}
.aboutprezidentBlock {
    max-width: 800px;
    margin: 20px auto 0 auto;
    padding: 40px 0;
    box-sizing: border-box;
}
.aboutTitletext {
    font-size: 28px;
    font-weight: 500;
    text-align: center;
}
.prezidentImg {
    max-width: 223px;
    max-height: 299px;
    float: left;
    padding: 0 10px 10px 0;
    object-fit: contain;

}

.aboutprezidentBlockInner {
    margin-top: 30px;
}

.prezidenttext {
    margin: 15px auto 15px auto;
    line-height: 1.8;
    opacity: 0.7;
    font-size: 14px;
    text-align: justify;
    @media screen and (max-width:525px) {
        font-size: 12px;
    }
}




///////////////    About About the chamber

.AboutChamber {
    max-width: 800px;
    margin: 20px auto 0 auto;
}

.AboutChamberTitle {
    font-size: 28px;
    font-weight: 700;
    margin-top: 8px;
        margin-bottom: 10px;
        font-weight: 600;
        line-height: 1.3;
        text-align: center;
}

.AboutChamberImg {
    width: 100%;
    object-fit: contain;
    margin: 20px 0;
}

.AboutChamberBlock {
    width: 100%;
    background-color: #003a65;
    padding: 50px 0;
    box-sizing: border-box;
    margin: 10px 0;
}

.AboutChamberBlueText {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 30px;
}

.AboutChamberBlueInfo {
    color: #fff;
    text-align: center;
    font-size: 18px;
    opacity: 0.8;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 30px;
}






///////////////////   ABOUT HISTORIY

.aboutHistoriyImg {
    width: 100%;
    object-fit: contain;
    margin: 30px 0 100px 0;
}

.aboutHistoriyMiniText {
    text-align: center;
    color: #003A65;
    font-weight: 500;
    font-size: 20px;
}



///////////////////////    BOARDS DIRECTORS

.setUpMeeting {
    color: #003A65;
    font-size: 20px;
    font-weight: 400;
    margin-top: 40px;
}

.setUpMeetingLi {
    font-size: 14px;
    margin: 10px 0;
    color: #404040;
}

.setUpMeetingText {
    font-size: 14px;
    margin-top: 30px;
    color: #404040;
}




/////////////////////////    About Office

.aboutOfficeCardBlock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    img{
        height: 220px;
        object-fit: cover;
        width: 200px;
    }
    @media screen and (max-width:525px) {
        justify-content: center;
    }
}

.aboutOfficeCard {
    width: 220px;
    margin: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.aboutOfficeCardImg {
    width: 220px;
    object-fit: contain;
}

.aboutOfficeCardTitle {
    color: #003A65;
    margin-top: 10px;
    text-align: center;
    font-weight: bold
}

.aboutOfficeCardDescription {
    color: #003A65;
    text-align: center;
}


/////////////////////////   ABOUT SOCIAL NETWORK

.aboutNetworkLink {
    text-decoration: none;
    color: #404040;
}
