.loader {
  background: #ffffff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(14px);
    width: 100%;
    height: 100%;
    background: transparent;
  }

}
